import React from 'react'
import AOS from 'aos';

const Developers = () => {
    AOS.init();
    return (
        <div>
            <h1>Developers</h1>
            
        </div>
    )
}

export default Developers
