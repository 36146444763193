import React from 'react'
import Home from './Home'
import { Routes, Route } from 'react-router-dom'
import Contact from './pages/Contact'
import Cybersecurity from './pages/Cybersecurity'
import Developers from './pages/Developers'
import Enterprise from './pages/Enterprise'
import HomeOS from './pages/HomeOS'
import Trydemo from './pages/Trydemo'
import Tos from './pages/Tos'
import Pop from './pages/Pop'

export const App = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route path="/Cybersecurity" element={<Cybersecurity />}></Route>
        <Route path="/Developers" element={<Developers />}></Route>
        <Route path="/Enterprise" element={<Enterprise />}></Route>
        <Route path="/HomeOS" element={<HomeOS />}></Route>
        <Route path="/Home" element={<Home />}></Route>
        <Route path="/trydemo" element={<Trydemo />}></Route>
        <Route path="/tos" element={<Tos />}></Route>
        <Route path="/pop" element={<Pop />}></Route>
      </Routes>
    </>

  )
}


export default App