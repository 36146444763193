import React from 'react'
import Navbar from '../navbar'
import Footer from '../Footer'
import AOS from 'aos';

const Cybersecurity = () => {
  AOS.init();
  return (
    <>
      <Navbar />
      <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">Fortify Your Digital Fortress with MeghaOS Cybersecurity Edition</h1>
              <h2 data-aos="fade-up" data-aos-delay="400">MeghaOS Cybersecurity Edition is purely customized for Forensic experts, Cybersecurity researchers, and Pentesters, etc. It comes with many advanced tools used to identify cyber-attacks and many packages needed for Pentesters also included in this OS.</h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div class="text-center text-lg-start">
                  <br />
                  <a href="#about" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Coming Soon</span>
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <img src="./img/hack.gif" class="img-fluid" alt="" />
            </div>
          </div>
        </div>

      </section>

      <Footer />

    </>
  )
}

export default Cybersecurity
