import React from 'react'

const Navbar = () => {
  return <>
    <nav class="navbar navbar-bg navbar-light navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img class="img" src="./img/logo.png" alt="" />
          <span>MeghaOS</span>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/trydemo">Try Demo</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Variants
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a class="dropdown-item" href="/Enterprise">Enterprise</a></li>
                <li><a class="dropdown-item" href="/HomeOS">HomeOS</a></li>
                <li><a class="dropdown-item" href="Cybersecurity">Cybersecurity</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://github.com/meghaos">Developers</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/contact">Contact</a>
            </li>
            <li>  <a href="https://firebasestorage.googleapis.com/v0/b/newagent-240c1.appspot.com/o/Meghaos-Home.iso?alt=media&token=1dbd777b-e6c8-4d65-845a-4a7f49014f98&_gl=1*1g7e8ln*_ga*NTc5MjM2NzYxLjE2ODI5ODczNDg.*_ga_CW55HF8NVT*MTY4NTczMzk0NC42LjEuMTY4NTczMzk5My4wLjAuMA.."><button class="getstarted" type="button" > Download </button></a> </li>
          </ul>
        </div>
      </div>
    </nav>
  </>

}

export default Navbar
