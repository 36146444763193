import React from 'react'

const Header = () => {
    return (
        <>
        
        <section id="hero" class="hero d-flex align-items-center">

        <div class="container">
      <div class="row">
        <div class="col-lg-5 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">Experience the Best of Both Worlds with MeghaOS</h1>
          <h2 data-aos="fade-up" data-aos-delay="400">MeghaOS combines the sleekness and familiarity of Windows and Mac with the power and security of Linux</h2>
          <div data-aos="fade-up" data-aos-delay="600">
            <div class="text-center text-lg-start">
              <p></p>
              <a href="/Trydemo" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span> Try Now</span>
                <i class="bi bi-arrow-right"></i>
              </a>
              <a href="https://youtu.be/nDCAsBQMiog" class="glightbox btn-get-started-1"><i class="bi bi-play-circle"></i><span> Watch Video</span></a>
            </div>
          </div>
        </div>
        <div class="col-lg-7 hero-img" data-aos="zoom-out" data-aos-delay="200">
          <img src="./img/server.gif" class="img-fluid" alt=""/>
        </div>
      </div>
    </div>

  </section>         
        </>
    )
}

export default Header
