import React from 'react'
import Navbar from '../navbar'
import Footer from '../Footer'
import AOS from 'aos';

const Enterprise = () => {
  AOS.init();
  return (
    <>
      <Navbar />
      <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">Empower Your Business with MeghaOS Enterprise Edition</h1>
              <h4 data-aos="fade-up" data-aos-delay="400">Unleash Efficiency, Security, and Scalability. Experience enhanced productivity, advanced security measures, and seamless scalability tailored to meet the demands of your organization.</h4>
              <p data-aos="fade-up" data-aos-delay="500">*Top 15 Best Cloud OS You Should Try: The Experts’ Recommendation; released by Ubuntupit, Code for Geek.</p>
              <div data-aos="fade-up" data-aos-delay="600">
                <div class="text-center text-lg-start">
                  <br />
                  <a href="https://buy.stripe.com/7sI0431kh8Xc2Fq8wz" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Buy Now</span>
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <img src="./img/server.gif" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <img src="./img/services.gif" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">What is MeghaOS Enterprise Edition?</h1>
              <h4 data-aos="fade-up" data-aos-delay="400">MeghaOS Enterprise Edition provides a consistent foundation across environments and the tools needed to deliver services and workloads faster for any application.</h4>
              <p data-aos="fade-up" data-aos-delay="500"><i class="bi bi-check-circle"></i> Reduces deployment friction and costs.</p>
              <p data-aos="fade-up" data-aos-delay="500"><i class="bi bi-check-circle"></i> Speeding time to value for critical workloads.</p>
              <p data-aos="fade-up" data-aos-delay="500"><i class="bi bi-check-circle"></i> Enabling development and operations teams to innovate together in any environment.</p>
            </div>

          </div>
        </div>
      </section>

      <section id="values" class="values">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <h2>Our Values</h2>
            <p>Outcomes of using MeghaOS Enterprise Edition</p>
          </header>

          <div class="row">

            <div class="col-lg-4">
              <div class="box" data-aos="fade-up" data-aos-delay="200">
                <img src="./img/values-1.png" class="img-fluid" alt="" />
                <h3>Stability in future-ready infrastructure</h3>
                <p>The operating system (OS) is the foundation of your architecture. By starting with an OS that runs anywhere, you can support your current and future requirements.Building on MeghaOS is a strategic decision that provides consistency across environments and stability as things change in the future.</p>
              </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="./img/values-2.png" class="img-fluid" alt="" />
                <h3>Consistent platform for development</h3>
                <p>MeghaOS Enterprise Edition gives you the stability and consistency that you need for development, and can run on any infrastructure and any cloud, it means your applications are developed on the same platform where they’ll be tested and deployed.</p>
              </div>
            </div>

            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="./img/values-3.png" class="img-fluid" alt="" />
                <h3>Standardize on Linux</h3>
                <p>Standardizing on MeghaOS Enterprise Edition across your IT infrastructure improves security, eases operational burdens, and reduces costs. MeghaOS’s consistent and stable administrative experience streamlines deployment and management of the OS by automating manual tasks</p>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section id="features" class="features">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <h2>Features</h2>
            <p>MeghaOS Enterprise Edition Features and benefits</p>
          </header>

          <div class="row">

            <div class="col-lg-6">
              <img src="./img/features.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Expand to the edge</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Continuous IT security</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Manage,Migrate or Upgrade</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Multiplatform support</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Subscription exclusives</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Open source innovation</h3>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </section>

      <main id="main">
        <section id="values" class="values">
          <div class="container" data-aos="fade-up">
            <header class="section-header">
              <h2>Our Plans</h2>
              <p>Choose your subscription for Enterprise Edition</p>
            </header>
            <div class="row">

              <div class="col-lg-4">
                <div class="box" data-aos="fade-up" data-aos-delay="200">
                  <h2>US $149</h2>
                  <img src="./img/self.jpeg" class="img-fluid" alt="" />
                  <h3>LIMITED SUPPORT<br />(1 YEAR)</h3>
                  <li>Does not include MeghaOS customer support.</li>
                  <li>Access to all MeghaOS updates.</li>
                  <li>Access to all MeghaOS Applications.</li>
                  <li>Cannot be stacked with other subscriptions.</li>
                  <li>Is not intended for production environments.</li>
                  <li>Can only be deployed on physical systems.</li>
                  <br />
                  <a class="btn-get-started scrollto justify-content-center" href="https://buy.stripe.com/7sI0431kh8Xc2Fq8wz">Buy Now</a>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="fade-up" data-aos-delay="400">
                  <h2>US $249</h2>
                  <img src="./img/support.png" class="img-fluid" alt="" />
                  <h3>STANDARD SUPPORT<br />(1 YEAR)</h3>
                  <li>24x7 for severity 1 or 2 cases.</li>
                  <li>Relationship Manager will be appointed to look into cases personally.</li>
                  <li>For all support cases, standard business hours of coverage are either 9 a.m. to 6 p.m. (09:00-18:00) local time for India or 9 a.m. to 5 p.m. (0900-1700) for outside India. Standard business hours do not include weekends or local public holidays.</li>
                  <li>WhatsApp Support for 1 or 2 cases on weekends.</li>
                  <li>Unlimited support cases.</li>
                  <br />
                  <a class="btn-get-started scrollto justify-content-center" href="https://buy.stripe.com/00gdUT9QN1uKfsccMQ">Buy Now</a>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="fade-up" data-aos-delay="600">
                  <h2>US $499</h2>
                  <img src="./img/claimservices.png" class="img-fluid" alt="" />
                  <h3>PREMIUM SUPPORT<br />(1 YEAR)</h3>
                  <li>24x7 for all severe cases.</li>
                  <li>Relationship Manager will be appointed to look into cases personally.</li>
                  <li>For all support cases, standard business hours of coverage are either 9 a.m. to 6 p.m. (09:00-18:00) local time for India or 9 a.m. to 5 p.m. (0900-1700) for outside India. Standard business hours do not include weekends or local public holidays.</li>
                  <li>WhatsApp Support for all cases on weekends.</li>
                  <li>Unlimited support cases.</li>
                  <br />
                  <a class="btn-get-started scrollto justify-content-center" href="https://buy.stripe.com/cN24kj3spgpE93OeUZ">Buy Now</a>
                </div>
              </div>

            </div>
          </div>
        </section>
      </main>
      <Footer />

    </>
  )
}

export default Enterprise
