import React from 'react'
import Navbar from '../navbar'
import Footer from '../Footer'
import AOS from 'aos';

const Tos = () => {
  AOS.init();
  return (
    <>
      <Navbar />

      <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
          <h1 >TERMS OF SERVICE AGREEMENT</h1>

          <h2>Effective Date: June 2023</h2><br></br>

          <p>This Terms of Service Agreement ("Agreement") is entered into between MeghaOS LLC ("Company") and the user ("User") of the MeghaOS operating system ("MeghaOS"). By accessing or using MeghaOS, User agrees to be bound by the terms and conditions of this Agreement.</p>

          <h3>1. Acceptance of Terms</h3>
          <p>User acknowledges and agrees to abide by the terms and conditions set forth in this Agreement. If User does not agree to these terms, they should refrain from using MeghaOS.</p>

          <h3>2. Use of MeghaOS</h3>
          <p>
            2.1 Permitted Use: User is granted a non-exclusive, non-transferable right to use MeghaOS for personal or internal business purposes, subject to the limitations and restrictions outlined in this Agreement.<br></br>
              2.2 Prohibited Activities: User shall not engage in any activity that violates applicable laws, infringes upon intellectual property rights, or interferes with the functionality or security of MeghaOS.
          </p>

          <h3>3. User Accounts</h3>
          <p>
            3.1 Account Creation: User may be required to create an account to access certain features of MeghaOS. User agrees to provide accurate and complete information during the account registration process.<br></br>
              3.2 Account Security: User is responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account. User shall promptly notify the Company of any unauthorized use of their account or any other security breaches.
          </p>

          <h3>4. Intellectual Property</h3>
          <p>
              4.1 Ownership: User acknowledges that MeghaOS, including all related trademarks, copyrights, and other intellectual property rights, is owned by the Company. User shall not use or reproduce any of the Company's intellectual property without prior written permission.<br></br>
              4.2 User Content: By submitting any content to MeghaOS, User grants the Company a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute the content for the purpose of operating and improving MeghaOS.
          </p>

          <h3>5. Privacy and Data Protection</h3>
          <p>
            5.1 Collection of Data: The Company may collect and process personal information in accordance with its Privacy Policy.<br></br>
              5.2 Cookies and Analytics: MeghaOS may use cookies and analytics tools to enhance User experience and gather usage information. By using MeghaOS, User consents to the use of cookies and data analytics as described in the Privacy Policy.
          </p>

          <h3>6. Disclaimers and Limitations of Liability</h3>
          <p>
            6.1 Warranty Disclaimer: MeghaOS is provided on an "as is" basis, without any warranties or representations of any kind. The Company does not guarantee the accuracy, reliability, or availability of MeghaOS.<br></br>
              6.2 Limitation of Liability: The Company shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from the use or inability to use MeghaOS, even if the Company has been advised of the possibility of such damages.
          </p>

          <h3>7. Termination</h3>
          <p>Either party may terminate this Agreement at any time for any reason. Upon termination, User's access to MeghaOS shall be immediately revoked, and User shall cease any further use of MeghaOS.</p>

          <h3>8. Governing Law and Jurisdiction</h3>
          <p>This Agreement shall be governed by and construed in accordance with the laws of the State of Delaware, USA. Any legal action or proceeding arising out of or relating to this Agreement shall be exclusively brought in the courts of the State of Delaware, USA.</p>

          <h3>9. Modifications to the Agreement</h3>
          <p>The Company reserves the right to modify or update this Agreement at any time. User will be notified of any changes, and continued use of MeghaOS after such notification constitutes acceptance of the modified terms.</p>


        </div >
      </section >

      <Footer />
    </>
  )
}

export default Tos
