import React from 'react'
import Navbar from './navbar'
import Header from './Header'
import Footer from './Footer'
import AOS from 'aos';
import CountUp from 'react-countup';

const Home = () => {
  AOS.init();
  return (
    <>
      <Navbar />
      <Header />
      <main id="main">

        <section id="idea" >
          <div class="container" data-aos="fade-up">
            <header class="section-header">
              <p>THE IDEA</p>
            </header>
            <img src="./img/idea-1.png" class="img-fluid" alt="" />
          </div>
        </section>

        <section id="counts" class="counts" >
          <div class="container" data-aos="fade-up">

            <div class="row gy-4">

              <div class="col-lg-3 col-md-6">
                <div class="count-box" >
                  <i class="bi bi-emoji-smile" style={{ color: 'yellow' }}></i>
                  <div>
                    <CountUp end={1468} duration={120} />
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="count-box">
                  <i class="bi bi-journal-richtext" style={{ color: 'red' }}></i>
                  <div>
                    <CountUp end={32} duration={120} />
                    <p>Updates</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="count-box">
                  <i class="bi bi-headset" style={{ color: 'blue' }}></i>
                  <div>
                    <CountUp end={648} duration={120} />
                    <p>Hours Of Support</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="count-box">
                  <i class="bi bi-people" style={{ color: 'green' }}></i>
                  <div>
                    <CountUp end={20} duration={120} />
                    <p>Hard Workers</p>

                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>
        <section id="features" class="features">

          <div class="container" data-aos="fade-up">

            <div class="row feature-icons" data-aos="fade-up">
              <header class="section-header">
                <h2>Features</h2>
                <p>Why choose MeghaOS?</p>
              </header>

              <div class="row">

                <div class="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
                  <img src="./img/features-3.png" class="img-fluid p-4" alt="" />
                </div>

                <div class="col-xl-8 d-flex content">
                  <div class="row align-self-center gy-4">

                    <div class="col-md-6 icon-box" data-aos="fade-up">
                      <i class="ri-line-chart-line"></i>
                      <div>
                        <h4>Open Source</h4>
                        <p>Embrace open source innovation with Enterprise-Level support for your business success</p>
                      </div>
                    </div>

                    <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                      <i class="ri-stack-line"></i>
                      <div>
                        <h4>Security and Compliance</h4>
                        <p>Stay one step ahead of intrusions with SELinux and mandatory access controls in MeghaOS.</p>
                      </div>
                    </div>

                    <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                      <i class="ri-brush-4-line"></i>
                      <div>
                        <h4>High Stability and Guaranteed Uptime of 99.9%</h4>
                        <p>Unleash the power of MeghaOS with consistent speed, ensuring optimal performance for years.</p>
                      </div>
                    </div>

                    <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                      <i class="ri-magic-line"></i>
                      <div>
                        <h4>Light Weight and Runs on any Hardware</h4>
                        <p>The lightweight OS that can run on a wide range of hardware from supercomputers to older PCs.</p>
                      </div>
                    </div>

                    <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                      <i class="ri-command-line"></i>
                      <div>
                        <h4>Ease of Maintenance</h4>
                        <p>With our centralized update system and installed software up to date with just a few clicks.</p>
                      </div>
                    </div>

                    <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                      <i class="ri-radar-line"></i>
                      <div>
                        <h4>24x7 Customer Service</h4>
                        <p>We offer 24x7 support, ensuring uninterrupted operations with minimal downtime for you.</p>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

          </div>
        </section>

      </main>


      <Footer />
    </>
  )
}

export default Home