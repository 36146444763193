import React from 'react'

const Footer = () => {
  return (
    <>
      <footer id="footer" class="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-5 col-md-12 footer-info">
                <a href="/" class="logo d-flex align-items-center">
                  <img src="./img/logo.png" alt="" />
                  <span>MeghaOS</span>
                </a>
                <div class="social-links mt-3">
                  <a href="https://twitter.com/MeghaOs" class="twitter"><i class="bi bi-twitter"></i></a>
                  <a href="https://www.facebook.com/meghasoftech" class="facebook"><i class="bi bi-facebook"></i></a>
                  <a href="https://www.instagram.com/meghaos" class="instagram"><i class="bi bi-instagram bx bxl-instagram"></i></a>
                  <a href="https://www.linkedin.com/company/meghasoftech/" class="linkedin"><i class="bi bi-linkedin bx bxl-linkedin"></i></a>
                </div>
              </div>

              <div class="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i class="bi bi-chevron-right"></i> <a href="/home">Home</a></li>
                  <li><i class="bi bi-chevron-right"></i> <a href="/contact">Contact us</a></li>
                  <li><i class="bi bi-chevron-right"></i> <a href="https://www.youtube.com/@meghaos2377">Videos</a></li>
                  <li><i class="bi bi-chevron-right"></i> <a href="/tos">Terms of service</a></li>
                  <li><i class="bi bi-chevron-right"></i> <a href="/pop">Privacy policy</a></li>
                </ul>
              </div>

              <div class="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i class="bi bi-chevron-right"></i> <a href="/trydemo">MeghaOS</a></li>
                  <li><i class="bi bi-chevron-right"></i> <a href="/Cybersecurity">Cybersecurity</a></li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>16192 Coastal Highway <br></br> Lewes, Delaware 19958 <br></br>USA</p>
                <p><strong>Phone:</strong> +1 848 228 2433<br></br><strong>Email:</strong> info@meghaos.co.in</p>

              </div>


            </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright">
            &copy; Copyright <strong><span>MeghaOS LLC 2023</span></strong>. All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
