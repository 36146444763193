import React from 'react'
import Navbar from '../navbar'
import Footer from '../Footer'
import AOS from 'aos';

const Pop = () => {
  AOS.init();
  return (
    <>
      <Navbar />

      <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
          <h1>Privacy Policy</h1>

          <h2>Effective Date: June 2023</h2><br></br>


          <p>This Privacy Policy ("Policy") describes how MeghaOS LLC ("Company," "we," "us," or "our") collects, uses, discloses, and protects the personal information of users ("User," "you," or "your") who access and use the MeghaOS operating system ("MeghaOS"). By accessing or using MeghaOS, you consent to the terms and practices described in this Policy.</p>

          <h3>1. Information We Collect</h3>
          <p>1.1 Personal Information: We may collect personal information that you voluntarily provide to us when you interact with MeghaOS. This may include:</p>
          <ul>
            <li>Contact Information: such as your name, email address, phone number, and mailing address.</li>
            <li>Account Information: if you create an account, we may collect login credentials and other account details.</li>
            <li>Communications: any information you provide when contacting us through email, chat, or other means of communication.</li>
            <li>Cookies and Similar Technologies (for website analytics and customization)</li>
          </ul>

          <p>1.2 Usage Information: When you access or use MeghaOS, we may automatically collect certain information about your usage patterns. This may include:</p>
          <ul>
            <li>Device Information: such as your IP address, device type, operating system, and browser details.</li>
            <li>Log Data: including information about your interactions with MeghaOS, such as access times, pages visited, and referral URLs.</li>
            <li>Cookies and Similar Technologies: we may use cookies, beacons, and other tracking technologies to collect information about your usage and preferences.</li>
          </ul>

          <h3>2. Use of Personal Information</h3>
          <p>2.1 Providing and Improving MeghaOS: We use the personal information we collect to provide and improve the functionality, performance, and user experience of MeghaOS. This may include:</p>
          <ul>
            <li>Customizing MeghaOS based on your preferences and settings.</li>
            <li>Analyzing usage patterns and trends to enhance and optimize our services.</li>
            <li>Responding to your inquiries, requests, and feedback.</li>
          </ul>

          <p>2.2 Communication: We may use your personal information to communicate with you, including:</p>
          <ul>
            <li>Sending important updates, notifications, and announcements related to MeghaOS.</li>
            <li>Responding to your customer support inquiries and providing assistance.</li>
          </ul>

          <p>2.3 Legal Compliance and Protection:</p>
          <ul>
            <li>We may use personal information as necessary to comply with applicable laws and regulations, protect the rights and safety of users and others, and prevent fraud and unauthorized access.</li>
          </ul>

          <h3>3. Sharing of Personal Information</h3>
          <p>3.1 Third-Party Service Providers:</p>
          <ul>
            <li>We may share personal information with trusted third-party service providers who assist us in operating, managing, and enhancing MeghaOS. These providers are contractually obligated to handle your information securely and use it only for the purposes specified by us.</li>
          </ul>

          <p>3.2 Legal Requirements and Safety:</p>
          <ul>
            <li>We may disclose personal information if required by law, legal process, or governmental request, or to protect the rights, property, or safety of the Company, our users, or others.</li>
          </ul>

          <p>3.3 Business Transfers:</p>
          <ul>
            <li>In the event of a merger, acquisition, or sale of all or a portion of our assets, personal information may be transferred to the acquiring entity. We will ensure appropriate safeguards are in place to protect your personal information during such transactions.</li>
          </ul>

          <h3>4. Data Retention and Security</h3>
          <p>4.1 Data Retention:</p>
          <ul>
            <li>We retain personal information for as long as necessary to fulfill the purposes outlined in this Policy or as required by law. When personal information is no longer needed, we securely dispose of it in accordance with our data retention practices.</li>
          </ul>

          <p>4.2 Data Security:</p>
          <ul>
            <li>We implement appropriate technical and organizational measures to protect personal information from unauthorized access, loss, misuse, or alteration. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</li>
          </ul>

          
          <h3>5. Your Rights and Choices</h3>
          <p>5.1 Access and Correction:</p>
          <ul>
            <li>You have the right to access and correct the personal information we hold about you. You can update your account information or request access to your personal data by contacting us using the information provided below.</li>
          </ul>

          <p>5.2 Opt-Out:</p>
          <ul>
            <li>You have the option to opt-out of receiving promotional communications from us. You can unsubscribe from marketing emails by following the instructions provided in the email or contacting us directly.</li>
          </ul>

          <p>5.3 Cookies and Tracking Technologies:</p>
          <ul>
            <li>You can set your browser to refuse all or some cookies or to alert you when cookies are being sent. However, certain features of MeghaOS may not function properly without cookies.</li>
          </ul>


          <h3>6. Third-Party Links and Services</h3>
          <p>MeghaOS may contain links to third-party websites, applications, and services that are not operated or controlled by us. This Privacy Policy does not apply to such third-party sites, and we are not responsible for their privacy practices. We encourage you to review the privacy policies of these third parties before providing any personal information.</p>

          <h3>7. Children's Privacy</h3>
          <p>MeghaOS is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove that information promptly.</p>

          <h3>8. Changes to this Policy</h3>
          <p>We reserve the right to modify or update this Privacy Policy at any time. We will notify you of any material changes by posting the updated Policy on MeghaOS or by other means of communication. Your continued use of MeghaOS after the effective date of the revised Policy constitutes your acceptance of the changes.</p>

          <h3>9. Contact Us</h3>
          <p>If you have any questions or concerns regarding this Privacy Policy, please contact us using the information provided below:</p>
          <p>MeghaOS LLC<br></br> Lewes, Delaware 19958, USA<br></br> info@meghaos.co.in<br></br> +1 848 228 2433</p>


        </div >
      </section >

      <Footer />
    </>
  )
}

export default Pop
