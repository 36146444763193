import React from 'react'
import Navbar from '../navbar'
import Footer from '../Footer'
import AOS from 'aos';

const Trydemo = () => {
  AOS.init();
  return (
    <>
      <Navbar />

      <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">MeghaOS Trial Edition</h1>
              <h2 data-aos="fade-up" data-aos-delay="400">MeghaOS is a lightweight, flexible integration platform that enables rapid integration across the extended enterprise on premise or in the cloud. If you need a platform that boosts efficiency, is easy to administer and control, and supports all major hardware platforms you've found it.</h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div class="text-center text-lg-start">
                  <br />
                  <a href="https://firebasestorage.googleapis.com/v0/b/newagent-240c1.appspot.com/o/Meghaos-Home.iso?alt=media&token=1dbd777b-e6c8-4d65-845a-4a7f49014f98&_gl=1*1g7e8ln*_ga*NTc5MjM2NzYxLjE2ODI5ODczNDg.*_ga_CW55HF8NVT*MTY4NTczMzk0NC42LjEuMTY4NTczMzk5My4wLjAuMA.." class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Download Now</span>
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <img src="./img/hero-img.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Trydemo
