import React from 'react'
import Navbar from '../navbar'
import Footer from '../Footer'
import AOS from 'aos';

const HomeOS = () => {
  AOS.init();
  return (
    <>
      <Navbar />
      <section id="hero" class="hero d-flex align-items-center">

        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">MeghaOS Home Edition</h1>
              <h2 data-aos="fade-up" data-aos-delay="400">MeghaOS is very simple and comes with all the necessary Applications and Softwares that people use daily. MeghaOS is very much designed for people who are new to the Linux world, many people coming from both a Windows or a Mac background will find plenty of familiar features along with some new ones that aren’t available in either Windows or Mac.</h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div class="text-center text-lg-start">
                  <br />
                  <a href="https://buy.stripe.com/eVa9EDd2Za1gbbW000" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Buy Now</span>
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
              <img src="./img/home.gif" class="img-fluid" alt="" />
            </div>
          </div>
        </div>

      </section>

      <section id="features" class="features">

        <div class="container" data-aos="fade-up">

          <header class="section-header">
            <h2>Features</h2>
            <p>MeghaOS Home Edition Features and benefits</p>
          </header>

          <div class="row">

            <div class="col-lg-6">
              <img src="./img/features.png" class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Familiar and User-Friendly Interface</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Extensive Software Compatibility</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Enhanced Security</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Lightweight and Efficient</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Cost-Effective Solution</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Support and Community</h3>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </section>

      <main id="main">

        <section id="values" class="values">

          <div class="container" data-aos="fade-up">

            <header class="section-header">
              <h2>Our Plans</h2>
              <p>Choose your subscription for Home Edition</p>
            </header>

            <div class="row">

              <div class="col-lg-4">
                <div class="box" data-aos="fade-up" data-aos-delay="200">
                  <h2>US $49</h2>
                  <img src="./img/self.jpeg" class="img-fluid" alt="" />
                  <h3>LIMITED SUPPORT</h3>
                  <li>Does not include MeghaOS customer support.</li>
                  <li>Access to all MeghaOS updates.</li>
                  <li>Access to all MeghaOS Applications.</li>
                  <li>Cannot be stacked with other subscriptions.</li>
                  <li>Is not intended for production environments.</li>
                  <li>Can only be deployed on physical systems.</li>
                  <br />
                  <a class="btn-get-started scrollto justify-content-center" href="https://buy.stripe.com/eVa9EDd2Za1gbbW000">Buy Now</a>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="fade-up" data-aos-delay="400">
                  <h2>US $99</h2>
                  <img src="./img/support.png" class="img-fluid" alt="" />
                  <h3>LIFETIME SUPPORT</h3>
                  <li>24x7 for severity 1 or 2 cases.</li>
                  <li>For all support cases, standard business hours of coverage are either 9 a.m. to 6 p.m. (09:00-18:00) local time for India or 9 a.m. to 5 p.m. (09:00-17:00) for outside India. Standard business hours do not include weekends or local public holidays.</li>
                  <li>Unlimited support cases.</li>
                  <br />
                  <a class="btn-get-started scrollto justify-content-center" href="https://buy.stripe.com/bIY0435Axdds3Ju145">Buy Now</a>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="fade-up" data-aos-delay="600">
                  <h2>US $199</h2>
                  <img src="./img/claimservices.png" class="img-fluid" alt="" />
                  <h3>PREMIUM LIFETIME SUPPORT</h3>
                  <li>24x7 for all severe cases.</li>
                  <li>For all support cases, standard business hours of coverage are either 9 a.m. to 6 p.m. (09:00-18:00) local time for India or 9 a.m. to 5 p.m. (09:00-17:00) for outside India. Standard business hours do not include weekends or local public holidays.</li>
                  <li>Unlimited support cases.</li>
                  <br />
                  <a class="btn-get-started scrollto justify-content-center" href="https://buy.stripe.com/cN2eYXaUR0qGgwg6oq">Buy Now</a>
                </div>
              </div>

            </div>

          </div>

        </section>.


      </main>
      <Footer />

    </>
  )
}

export default HomeOS
