import React, { useState } from 'react'
import Navbar from '../navbar'
import Footer from '../Footer'
import AOS from 'aos';

const Contact = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value })
  }

  const submitData = async (event) => {
    event.preventDefault();
    const { name,
      email,
      subject,
      message } = userData;
    const res = await fetch("https://meghaos-default-rtdb.asia-southeast1.firebasedatabase.app/contactForm.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          subject,
          message
        })
      },
    );

    if (res) {
      setUserData({
        name: "",
        email: "",
        subject: "",
        message: "",
      })
      alert("Message Sent Succesfully!")
    } else {
      alert("Try Again")
    }
  }
  AOS.init();
  return (
    <>
      <Navbar />

      <section id="contact" class="contact">

        <div class="container" data-aos="fade-up">

          <header class="section-header">


            <section id="faq" class="faq">

              <div class="container" data-aos="fade-up">

                <header class="section-header">
                  <h2>F.A.Q</h2>
                  <p>Frequently Asked Questions</p>
                </header>

                <div class="row">
                  <div class="col-lg-6">

                    <div class="accordion accordion-flush" id="faqlist1">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                            What's a MeghaOS subscription?
                          </button>
                        </h2>
                        <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                          <div class="accordion-body">
                            MeghaOS subscription gives you technology, assurance, and expertise. It gives you access to enterprise-ready software, updates, and information and support services that span your entire application infrastructure, life cycle, and architecture, and you can receive the latest product versions.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                            How long does a subscription last?
                          </button>
                        </h2>
                        <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                          <div class="accordion-body">
                            Subscriptions are typically 1 year in length and are provided on a per-instance or per-installation subscription basis. Subscription start dates are governed by the original purchase date (if not specified otherwise in a contract). Subscriptions purchased online activate immediately. We'll adjust subscription start dates in the case of a error, but won't adjust them due to delayed hardware or software deployments on the customer's end.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                            Are there subscription support levels?
                          </button>
                        </h2>
                        <div id="faq-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                          <div class="accordion-body">
                            Yes. You can find information on OS variants pages and on the different severity support levels in the Customer Portal.
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-lg-6">

                    <div class="accordion accordion-flush" id="faqlist2">

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                            What's the difference between a Home and Enterprise variant?
                          </button>
                        </h2>
                        <div id="faq2-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                          <div class="accordion-body">
                            Home/Desktop variants are designed for single users accomplishing straightforward computing tasks. Enterprise variants have more client/server capabilities, such as developer tools and basic hypervisor functionalities.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-2">
                            Are Limited-support subscriptions only for physical hosts?
                          </button>
                        </h2>
                        <div id="faq2-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                          <div class="accordion-body">
                            Yes, self-support subscriptions are designed to be deployed on physical hosts, not virtual hosts.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-3">
                            Can Home variant be used on servers?
                          </button>
                        </h2>
                        <div id="faq2-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                          <div class="accordion-body">
                            No, Home variants aren't developed to function on servers, and are developed for individual use only. Server/Enterprise variants include more packages that allow them to be used by multiple clients.
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>

            </section>

            <p>Contact Us</p>
          </header>

          <div class="row gy-4">

            <div class="col-lg-6">

              <div class="row gy-4">
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <p>16192 Coastal Highway <br></br> Lewes, Delaware 19958, USA</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>Support Team<br />+1 8482282433</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>info@meghaos.co.in<br /></p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-clock"></i>
                    <h3>Open Hours</h3>
                    <p>Monday - Friday : 9:00AM - 05:00PM</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-6">
              <form method="POST" class="form  php-email-form" >
                <div class="row gy-4">

                  <div class="col-md-6">
                    <input type="text" name="name" class="form-control" value={userData.name} onChange={postUserData} placeholder="Your Name" required />
                  </div>

                  <div class="col-md-6 ">
                    <input type="email" class="form-control" name="email" value={userData.email} onChange={postUserData} placeholder="Your Email" required />
                  </div>

                  <div class="col-md-12">
                    <input type="text" class="form-control" name="subject" value={userData.subject} onChange={postUserData} placeholder="Subject" required />
                  </div>

                  <div class="col-md-12">
                    <textarea class="form-control" name="message" rows="6" value={userData.message} onChange={postUserData} placeholder="Message" required></textarea>
                  </div>

                  <button type="submit" onClick={submitData}>Send Message</button>

                </div>
              </form>

            </div>

          </div>

        </div>

      </section>
      <Footer />

    </>
  )
}

export default Contact
